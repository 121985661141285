import { storageSet } from '../utils/localStorage';

const doFormSubmit = (e) => {
  const form = e.target;
  e.preventDefault();

  function isEmail(email) {
    const regexp = /^[a-zA-Z0-9!#$%&*+/=?_`{|}~-]+([\\.-][a-zA-Z0-9!#$%&*+/=?^_`{|}~-]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,})+$/;
    return regexp.test(email);
  }

  function httpPOST(url, data, fnSuccess, fnError) {
    let http = false;
    try {
      http = new window.XMLHttpRequest();
    } catch (httpPOSTError) {
      http = false;
    }
    if (!http) {
      return;
    }
    if (http.readyState !== 0) {
      this.http.onreadystatechange = () => {};
      this.http.abort();
    }
    http.onreadystatechange = () => {
      if (http.readyState !== 4) {
        return;
      }
      if (http.status === 200 || http.status === 0) {
        if (fnSuccess) {
          fnSuccess(http.responseText);
        }
      } else if (fnError) {
        fnError(http);
      }
    };
    // serialize
    const paramChunks = [];
    let paramString = '';
    Object.keys(data).forEach((key) => {
      paramChunks.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
      );
    });
    paramString = paramChunks.join('&');
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    // http.setRequestHeader("Content-length", paramString.length);
    // http.setRequestHeader("Connection", "close");
    http.send(paramString);
  }

  function loading(show) {
    try {
      if (show) {
        form.classList.add('loading');
      } else {
        form.classList.remove('loading');
      }
    } catch (loadingError) {
      // older browsers may not support classList
    }
  }

  function error(errorMessage) {
    loading(false);

    let child;
    for (let i = 0; i < form.getElementsByTagName('*').length; i += 1) {
      child = form.getElementsByTagName('*')[i];
      if (child.getAttribute('data-subscribe-validation') === 'error') {
        break;
      }
    }

    if (child) {
      child.innerText = errorMessage || '';
      child.style.display = errorMessage ? '' : 'none';
    }
  }

  function thanks(responseData) {
    loading(false);
    let data;
    try {
      data = window.JSON.parse(responseData);
    } catch (thanksError) {
      data = { thanksError: 'Invalid response received' };
    }
    if (data.ok && data.goto) {
      storageSet('stickySubscribeClosed', 'yes');
      window.location.assign(data.goto);
    } else {
      error(data.error || 'System error!');
    }
  }

  const email = form.elements.email.value;
  if (email === '') {
    return false;
  }
  if (isEmail(email)) {
    error('');
    loading(true);
    httpPOST(
      window.location.href,
      {
        event: 'subscribe.subscribe#submit',
        email,
        to: form.getAttribute('data-to'),
        tags: form.getAttribute('data-tags'),
      },
      thanks,
      error,
    );
  } else {
    const msg = form.elements.email.getAttribute('data-err');
    error(msg || 'Invalid email');
  }
  return false;
};

const isSubscribeForm = (e) => {
  if (e.target.getAttribute('data-subscribe-node') === 'form') {
    doFormSubmit(e);
  }
};

function init() {
  if (!document.body.dataset.subscribe) {
    document.body.addEventListener('submit', isSubscribeForm);
    document.body.dataset.subscribe = 1;
  }
}
init();

document.addEventListener('turbo:load', () => {
  init();
});
